import "./index.css";
import Carousel from 'better-react-carousel';
import { TextField, TextArea, Select, ThemeProvider } from "@gupshup-frontend/gupshup-ts-components";
import { useState } from "react";
import { variableCount } from "../../helper/utils";
import { DYNAMIC_HELP_TEXT } from "../../helper/constants";

const CarouselComponent = ({
  carouselCards,
  variables,
  carouselDetails,
  setCarouselDetails
}) => {
  const BodyComponent = (body, selectedComponent, handleChange) => {
    const variableHandler = (index, data) => {
      selectedComponent.parameters[index].text = data;
      handleChange(selectedComponent);
    };
    const variableComponents = ({ body }) => {
      const numberOfVariables = variableCount(body);
      const variableElements = [];

      for (let i = 0; i < numberOfVariables; i++) {
        variableElements.push(
          <div
            style={{ marginBottom: "16px", display: "flex", flexDirection: "column" }}
            key={`carousel-variable-${i}`}
          >
            <Select
              handleChange={(value) => {
                variableHandler(i, value);
              }}
              showSearch
              id={`carousel-variable-${i}`}
              data-testid={`carousel-variable-${i}`}
              maxHeightOfDropdown="300px"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                style: {
                  maxHeight: 300,
                },
              }}
              items={variables}
              required
              label={`Select value for {{${i + 1}}}`}
              value={selectedComponent?.parameters?.[i]?.text}
            />
          </div>
        );
      }

      return variableElements;
    };

    return (
      <>
        <TextArea
          fullWidth
          color="primary"
          data-testid="nic-carousel-body"
          label="Carousel Body"
          rows={3}
          value={body}
          inputProps={{ readOnly: true }}
          disabled
          id="nic-carousel-body"
        />
        <div style={{ marginTop: "24px" }}>{variableComponents({ body })}</div>
        <br />
      </>
    );
  };

  const MediaComponent = (type, selectedComponent, handleChange) => {
    const tempSelectedComponent = selectedComponent;
    return (
      <div>
        <div className="nic-carousel-media-component">
          <TextField
            fullWidth
            color="primary"
            data-testid="nic-carousel-media"
            label={`Enter ${type.toLowerCase()} URL`}
            value={selectedComponent?.parameters?.[0][selectedComponent?.parameters?.[0]?.type]?.link}
            onChange={(e) => {
                tempSelectedComponent.parameters[0][selectedComponent.parameters[0].type].link = e.target.value;
                handleChange(tempSelectedComponent);
            }}
            required
          />
        </div>
      </div>
    );
  };

  const ButtonComponent = ({ button, selectedComponent, handleChange }) => {
    const tempSelectedComponent = selectedComponent;
    const [param, setParam] = useState(selectedComponent?.parameters?.[0]?.payload);
    if (button.type === "QUICK_REPLY" || (button.type === "URL" && button.urlType === "STATIC") || button.type === "PHONE_NUMBER")
        return <></>
    else return <div style={{marginBottom: "16px"}}>
        <TextField
            fullWidth
            color="primary"
            data-testid="nic-carousel-button"
            label={`Button URL Params`}
            helperText={DYNAMIC_HELP_TEXT[button.urlType ]}
            required
            value={param}
            onChange={(e) => setParam(e.target.value)}
            onBlur={(e) => {
                tempSelectedComponent.parameters[0].payload = e.target.value
                handleChange(tempSelectedComponent)
            }}
        />
    </div>
}

  return (
    <ThemeProvider>
      <div>
        <Carousel gap={10} loop showDots mobileBreakpoint={500}>
          {carouselCards && carouselCards.map((card, index) => {
            const tempCarouselDetails = { ...carouselDetails };
            let selectedCard = tempCarouselDetails?.components?.[0]?.cards?.[index];

            return (
              <Carousel.Item key={index}>
                <p style={{ color: 'gray' }}>Carousel {index + 1}</p>
                <div style={{ display: "grid" }}>
                  {card?.components?.map((comp) => {
                    const handleChange = (updatedSelectedValue) => {
                      let selectedCard = tempCarouselDetails?.components?.[0]?.cards?.[index];
                      let selectedIndex = selectedCard?.components?.findIndex((com) => com?.type?.toLowerCase() === comp.type);

                      if (selectedIndex !== -1) {
                        selectedCard.components[selectedIndex] = updatedSelectedValue;
                        setCarouselDetails(tempCarouselDetails);
                      }
                    };

                    const selectedComponent = selectedCard?.components?.find((com) => {
                      if (comp.type !== "buttons") {
                        return com?.type?.toLowerCase() === comp.type;
                      } else {
                        return com?.type?.toLowerCase() === "button";
                      }
                    });

                    switch (comp.type) {
                      case "body":
                        return BodyComponent(comp.text, selectedComponent, handleChange);
                      case "header": return MediaComponent(comp.format, selectedComponent, handleChange);
                      case "buttons":
                        return comp.buttons.map((button, i) => {
                            const selectedButtonComp = selectedCard?.components?.find((com) => {
                                    return com?.type?.toLowerCase() === "button" && com.index === i
                            })
                            return <ButtonComponent key={"button" + button?.index} button={button} selectedComponent={selectedButtonComp} handleChange={handleChange} />
                        })
                      default: return <></>
                    }
                  })}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </ThemeProvider>
  );
};

export default CarouselComponent;
