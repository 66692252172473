import { variableCount, isImage, isVideoUrl, sendMessageToParent } from "../../helper/utils";
import { Button, Select, TextArea, ThemeProvider, TextField } from "@gupshup-frontend/gupshup-ts-components";
import { useState, useEffect } from "react";
import "./index.css";
import CarouselComponent from "../../components/CarouselComponent";
import { DYNAMIC_HELP_TEXT } from "../../helper/constants";
const OracleCarousel = () => {
  const [templateParamsObj, setTemplateParamsObj] = useState();
  const [inputField, setInputField] = useState();
  const [carouselDetails, setCarouselDetails] = useState();
  const [variables, setVariables] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();

  useEffect(() => {
    sendMessageToParent("ready");
    window.onmessage = (event) => {
        if(event.data.name === "props") {
            const tempVariables = event.data?.variables.map((v) => {
                return {
                    label: v.name,
                    value: v.name,
                }
            })
            setVariables(tempVariables)
            setSelectedTemplate(event.data?.template)
        }
    }
  }, [])

  useEffect(() => {
    if(selectedTemplate)
      sendMessageToParent({type: "reSize", height: document.getElementsByTagName("body")[0].scrollHeight + 50});
  }, [selectedTemplate])

  const validateFinalData = () => {
    const variableValues = Object.values(templateParamsObj)
    for (let i = 1; i <= variableValues.length; i++) {
      if (variableValues[i - 1] === `{{${i}}}` || variableValues[i - 1] === "") {
        sendMessageToParent({error: "Select values for all variables", wa_template_json: {}});
        return false;
      }
    }
    const cards = carouselDetails?.components[0]?.cards;
    for (let cardIndex = 0; cardIndex < cards.length; cardIndex++) {
      const card = cards[cardIndex];
      for (let componentIndex = 0; componentIndex < card.components.length; componentIndex++) {
        const component = card.components[componentIndex];
        switch (component.type) {
          case "HEADER":
            const url = component.parameters[0][`${component.parameters[0].type}`].link;
            if (!url || url === "") {
              sendMessageToParent({error: `Error in carousel ${cardIndex +1}, URL not present`, wa_template_json: {}});
              return false;
            } else if ((component.parameters[0].type === "image" && !isImage(url)) ||
              (component.parameters[0].type === "video" && !isVideoUrl(url))) {
                sendMessageToParent({error: `Error in carousel ${cardIndex +1}, Invalid URL`, wa_template_json: {}});
              return false;
            }
            break;
          case "BODY":
            for (let i = 0; i < component.parameters.length; i++) {
              if (variableCount(component.parameters[i].text) !== 0 || component.parameters[i].text === "") {
                sendMessageToParent({error: `Error in carousel ${cardIndex +1}, Select values for all variables`, wa_template_json: {}});
                return false;
              }
            }
            break;
          case "BUTTON":
            if (component.sub_type === "URL") {
                  if (!component.parameters[0].payload || component.parameters[0].payload === "") {
                    sendMessageToParent({error: `Error in carousel ${cardIndex +1}, Provide values for button params`, wa_template_json: {}});
                    return false;
                  }
            }
            break;
        }
      }
    }
    return true;
  }
  useEffect(() => {
    if (selectedTemplate) {
      const variablesCount = variableCount((selectedTemplate?.body || "") + (selectedTemplate?.header || ""))
      let tempTemplateParamsObj = {};
      for (let i = 1; i <= variablesCount; i++) {
        tempTemplateParamsObj[`{{${i}}}`] = `{{${i}}}`;
      }
      setTemplateParamsObj(tempTemplateParamsObj)
      if (selectedTemplate?.carouselCards) {
        let carouselData = { components: [{ type: "CAROUSEL", cards: [] }] };
        selectedTemplate.carouselCards.map((card, index) => {
          let compDetails = { card_index: index, components: [] };
          card?.components.map((component) => {
            let comp = {};
            switch (component.type) {
              case "header":
                comp = {
                  type: "HEADER",
                  parameters: [
                    {
                      type: component.format.toLowerCase(),
                      [component.format.toLowerCase()]: {
                        link: ""
                      }
                    }
                  ]
                }
                compDetails.components.push(comp)
                break;
              case "body":
                const variables = variableCount(component.text);
                if (variables > 0) {
                  comp = { type: "BODY", parameters: [] }
                  for (let i = 1; i <= variables; i++) {
                    comp.parameters.push({
                      type: "text",
                      text: `{{${i}}}`,
                    })
                  }
                  compDetails.components.push(comp)
                }
                break;
              case "buttons":
                component.buttons.map((button, index) => {
                  if (button.type === "URL" && button.urlType !== "STATIC") {
                    comp = {
                      type: "BUTTON",
                      index: index,
                      sub_type: button.type,
                      parameters: [
                        {
                          type: "PAYLOAD",
                          payload: ""
                        }
                      ]
                    }
                    compDetails.components.push(comp)
                  }
                })
                break;
              default:
                break;
            }
          })
          carouselData.components[0].cards.push(compDetails);
        })
        setCarouselDetails(carouselData)
      }
    }
  }, [selectedTemplate])
  const variableComponents = () => {
    const message = selectedTemplate?.body
    const header = selectedTemplate?.header
    const numberOfVariables = variableCount(message + header)
    const variableElements = []
    for (let i = 1; i <= numberOfVariables; i++) {
      variableElements.push(
        <div className="oru-variable-component" key={`template-variable-${i}`}>
          <Select
            id={`template-variable-${i}`}
            data-testid={`template-variable-${i}`}
            showSearch
            handleChange={(value) => {
              variableHandler(`{{${i}}}`, value)
            }}
            items={variables}
            label={`Select value for {{${i}}}`}
            value={templateParamsObj?.[`{{${i}}}`]}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              style: {
                maxHeight: 300,
              },
            }}
          />
        </div>
      )
    }
    return variableElements
  }

  const variableHandler = (variable, data) => {
    setTemplateParamsObj({
      ...templateParamsObj,
      [variable]: data !== "" ? data : variable,
    })
  };

  const handleSave = () => {
    const parameterList = Object.values(templateParamsObj) || [];
    const cards = carouselDetails?.components[0]?.cards;
    for (let cardIndex = 0; cardIndex < cards.length; cardIndex++) {
      const card = cards[cardIndex];
      for (let componentIndex = 0; componentIndex < card.components.length; componentIndex++) {
        const component = card.components[componentIndex];
        if(component.type === "BODY") {
          component.parameters.map((param) => {
            parameterList.push(param.text)
          })
        }
      }
    }
      sendMessageToParent({ 
        parameterList: parameterList, 
        wa_template_json: carouselDetails, 
        inputField: inputField,
        error: null });
  }

  return (
    <ThemeProvider>
      <div style={{margin: "auto", marginTop: "36px"}}>
      <div>
        <TextArea
          fullWidth
          id="oru-template-body"
          data-testid="oru-template-body"
          color="primary"
          label="Template Body"
          rows={3}
          value={
            (selectedTemplate?.header ? `${selectedTemplate.header}\n` : "") +
            selectedTemplate?.body +
            (selectedTemplate?.footer ? `\n${selectedTemplate?.footer}` : "")
          }
          inputProps={{ readOnly: true }}
          disabled
        />
        <div style={{ marginTop: "24px" }}>
          {variableComponents()}
        </div>
        {(selectedTemplate?.button_type === "DYNAMIC" ||
              selectedTemplate?.button_type === "DYNAMIC_LINK_TRACK") &&
              <div>
                <TextField
                  id={"oru-button-param"}
                  data-testid={"oru-button-param"}
                  fullWidth
                  color="primary"
                  label={`Button URL Params`}
                  value={inputField?.dynamicButton}
                  helperText={DYNAMIC_HELP_TEXT[selectedTemplate?.button_type]}
                  required
                  onChange={(e) => setInputField((prevState) => ({
                    ...prevState,
                    dynamicButton: e.target.value
                  }))}
                />
              </div>}
        <br />
        <p>Carousel details</p>
        <div style={{ padding: "16px", border: "1px solid #e1e1e1" }}>
          <CarouselComponent
            carouselCards={selectedTemplate?.carouselCards}
            variables={variables}
            carouselDetails={carouselDetails}
            setCarouselDetails={setCarouselDetails} />
        </div>
        <Button 
         id="oru-carousel-button" 
         data-testid="oru-carousel-button" 
         className="oru-carousel-button" 
         onClick={() => {
          const result = validateFinalData();
          if(result) {
            handleSave();
          }
        }}>Save</Button>
      </div>
      </div>
    </ThemeProvider>
  );
}
export default OracleCarousel;
