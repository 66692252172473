import { useState, useEffect } from "react";
import { getChannels, getSummary, getMetadata, getTableData, getFilteredTableData, getExportData } from "../../utils/api";
import { csvmaker, sendMessageToParent } from "../../helper/utils";
import { DLRDashboard } from "@gs/integration-components";
import { DLRColumns } from "../../utils/constants";
const Dashboard = () => {
    const [appDetails, setAppDetails] = useState({});
    const [orgId, setOrgId] = useState();
    useEffect(() => {
        sendMessageToParent("dashboardReady");
        window.onmessage = async (event) => {
            if(event.data.name === "getInstallId" && event.data.installId) {
                try {
                    setOrgId(event.data.installId);
                    const res = await getChannels(event.data.installId);
                    setAppDetails(res.data);
                } catch (err) {
                    console.log("error-------------", err)
                }
            }
        }
    }, [])
    const handleCallback = async (data, type) => {
        let summary, meta;
        switch(type) {
            case "channelChange":
                summary = await getSummary(orgId, data);
                meta = await getMetadata(orgId, data);
                const tableData = await getTableData(orgId, data);
                return {summary, templates: meta.templates, programs: meta.programs, tableData};
            case "programChange":
                const programFilteredTableData = await getFilteredTableData(orgId, data);
                return programFilteredTableData;
            case "templateChange":
                const templateFilteredTableData = await getFilteredTableData(orgId, data);
                return templateFilteredTableData;
            case "filterChange":
                const filteredTableData = await getFilteredTableData(orgId, data);
                return filteredTableData;
            case "export":
                const filteredData = await getExportData(orgId, data);
                let formatedTableData = filteredData.map(data => {
                    let launchTime = String(new Date(data.launchTimestamp).toLocaleString());
                    let lastTime = String(new Date(data.lastTimestamp).toLocaleString());
                    return {...data, launchTimestamp: launchTime.replace(",",""),lastTimestamp:lastTime.replace(",","")}
                })
                const csvData = csvmaker(formatedTableData);
                const blob = new Blob([csvData], { type: 'text/csv' });
                return blob;
            default:
                break;
        }
    }
    return(
        <>
            {appDetails?.channels?.length > 0 ? <DLRDashboard
             columns={DLRColumns}
             channels={appDetails?.channels?.map(channel => { return {label: channel.channelName, value: channel.channelId}})} 
             callbackMethod={handleCallback} /> : <p>No accounts found</p>}
        </>
    )
}
export default Dashboard;
