import { useEffect, useState } from "react";
import { sendMessageToParent, variableCount, extractVariableValues, isMediaUrlValid, getLabelFromButtonType } from "../../helper/utils";
import { Button, Select, TextArea, ThemeProvider, TextField } from "@gupshup-frontend/gupshup-ts-components";
import { CustomInput } from "@gs/integration-components";
const ComplexTemplates = () => {
    const staticButtonTypes = ["QUICK_REPLY", "PHONE_NUMBER"]
    const [variables, setVariables] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [templateParamsObj, setTemplateParamsObj] = useState();
    const [buttonParamsObj, setButtonParamsObj] = useState([]);
    const [showPopover, setShowPopover] = useState(false);
    const [showMediaPopover, setShowMediaPopover] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [media, setMedia] = useState();
    useEffect(() => {
        sendMessageToParent("ready");
        window.onmessage = (event) => {
            if (event.data.name === "props") {
                const tempVariables = event.data?.variables.map((v) => {
                    return {
                        label: v.name,
                        value: v.name,
                    }
                })
                setVariables(tempVariables)
                setSelectedTemplate(event.data?.template)
            }
        }
    }, [])

    useEffect(() => {
        if (selectedTemplate) {
            sendMessageToParent({ type: "reSize", height: document.getElementsByTagName("body")[0].scrollHeight + 50 });
            let tempTemplateParamsObj = {};
            const variablesCount = variableCount((selectedTemplate?.body || "") + (selectedTemplate?.header || ""))
            for (let i = 1; i <= variablesCount; i++) {
                tempTemplateParamsObj[`{{${i}}}`] = `{{${i}}}`;
            }
            setTemplateParamsObj(tempTemplateParamsObj)
        }
    }, [selectedTemplate])

    const variableHandler = (variable, data) => {
        setTemplateParamsObj({
            ...templateParamsObj,
            [variable]: data !== "" ? data : variable,
        })
    };

    const variableComponents = () => {
        const message = selectedTemplate?.body
        const header = selectedTemplate?.header
        const numberOfVariables = variableCount(message + header)
        const variableElements = []
        for (let i = 1; i <= numberOfVariables; i++) {
            variableElements.push(
                <div className="oru-variable-component" key={`template-variable-${i}`}>
                    <Select
                        id={`template-variable-${i}`}
                        data-testid={`template-variable-${i}`}
                        showSearch
                        handleChange={(value) => {
                            variableHandler(`{{${i}}}`, value)
                        }}
                        items={variables}
                        label={`Select value for {{${i}}}`}
                        value={templateParamsObj?.[`{{${i}}}`]}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            style: {
                                maxHeight: 300,
                            },
                        }}
                    />
                </div>
            )
        }
        return variableElements
    }
    const handleVariableSelection = (value, index, button, isDropdown = false, isFallback = false) => {
        const buttonType = button?.type?.toLowerCase() === "copy_code" ? "coupon_code" : "text";
        const oldValue = buttonParamsObj.find(obj => obj?.index === index);
        const newValue = isDropdown ? (oldValue?.parameters?.[0]?.[buttonType] || "") + `{{${value}}}` : value;
        let tempButtonParam = {
            type: "button",
            sub_type: button?.type,
            index: index,
            parameters: [{
                [buttonType]: isFallback ? (oldValue?.parameters?.[0]?.[buttonType] || "") : newValue,
                type: buttonType,
            }]
        }
        if (isFallback) {
            tempButtonParam.parameters[0]["defaultValue"] = value;
        }
        let tempButtonParamsObj = buttonParamsObj.slice();
        if (oldValue)
            tempButtonParamsObj = tempButtonParamsObj.map(obj => [tempButtonParam].find(o => o.index === obj.index) || obj)
        else tempButtonParamsObj.push(tempButtonParam);
        setButtonParamsObj(tempButtonParamsObj);
        setShowPopover(false);
    }
    const handleSave = () => {
        let parameterList = Object.values(templateParamsObj) || [];
        if (media) {
            const mediaVariables = extractVariableValues(media);
            parameterList = parameterList.concat(mediaVariables);
        }
        buttonParamsObj.map((button) => {
            const type = button?.parameters?.[0]?.type
            const value = button?.parameters?.[0]?.[type]
            const variables = extractVariableValues(value)
            parameterList = parameterList.concat(variables);
        })
        sendMessageToParent({ 
            parameterList,
            media,
            wa_template_json: { components: buttonParamsObj },
            error: null });
    }
    const validateFinalData = () => {
        // Template body validation
        const variableValues = Object.values(templateParamsObj || {})
        for (let i = 1; i <= variableValues.length; i++) {
            if (variableValues[i - 1] === `{{${i}}}` || variableValues[i - 1] === "") {
                sendMessageToParent({ error: "Select values for all body variables", wa_template_json: {} });
                return false;
            }
        }
        // Media validation
        if (["IMAGE", "VIDEO", "DOCUMENT"].includes(selectedTemplate?.type)) {
            if (!media) {
                sendMessageToParent({ error: "Provide value for media", wa_template_json: {} });
                return false;
            }
            const mediaVariableCount = variableCount(media);
            if (mediaVariableCount === 0 && !isMediaUrlValid(selectedTemplate?.type, media)) {
                sendMessageToParent({ error: "Provide valid media URL", wa_template_json: {} });
                return false;
            }
        }
        // waTemplateJson validation
        const dynamicButtons = selectedTemplate?.buttons?.filter((button) => {
            let condition = !staticButtonTypes.includes(button?.type);
            if(button?.type === "URL" && button?.urlType === "STATIC")
                condition = false;
            return condition
        }) || []
        if (dynamicButtons.length !== buttonParamsObj.length) {
            sendMessageToParent({ error: "Provide value for all button fields", wa_template_json: {} });
            return false;
        }
        for (let i = 0; i < buttonParamsObj.length; i++) {
            const parametersObj = buttonParamsObj[i]?.parameters?.[0]
            if (!parametersObj[parametersObj?.type]) {
                sendMessageToParent({ error: "Provide value for all button fields", wa_template_json: {} });
                return false;
            }
        }
        return true;
    }
    const getHelperText = (button) => {
        if(button.type === "URL") {
            if(button.urlType === "DYNAMIC") {
                return "Enter Button Url Path"
            } else return "Enter the full url you want the user to be redirected to on clicking the CTA button"
        }
        return ""
    }
    return (
        <ThemeProvider>
            <div style={{ margin: "auto", marginTop: "36px" }}>
                <div>
                    <TextArea
                        fullWidth
                        id="oru-template-body"
                        data-testid="oru-template-body"
                        color="primary"
                        label="Template Body"
                        rows={3}
                        value={
                            (selectedTemplate?.header ? `${selectedTemplate.header}\n` : "") +
                            selectedTemplate?.body +
                            (selectedTemplate?.footer ? `\n${selectedTemplate?.footer}` : "")
                        }
                        inputProps={{ readOnly: true }}
                        disabled
                    />
                    <div style={{ marginTop: "24px" }}>
                        {variableComponents()}
                    </div>
                    {
                        ["IMAGE", "VIDEO", "DOCUMENT"].includes(selectedTemplate?.type) ?
                            <div style={{ marginBottom: "16px" }}>
                                <p>Media</p>
                                <CustomInput
                                    label={`${selectedTemplate?.type?.replaceAll(/\S*/g, word =>
                                        `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
                                    )} media public URL`}
                                    value={media}
                                    variables={variables}
                                    handleInputChange={(value) => setMedia(value)}
                                    handleDropdownChange={(value) => {
                                        setShowMediaPopover(false)
                                        setMedia((media || "") + `{{${value}}}`)
                                    }}
                                    showPopover={showMediaPopover}
                                    setShowPopover={setShowMediaPopover}
                                />
                            </div> : null
                    }
                    <div>
                        <p style={{ marginBottom: "24px" }}>Button Details</p>
                        {
                            selectedTemplate?.buttons?.length > 0 &&
                            selectedTemplate.buttons.map((button, index) => {
                                const buttonType = button?.type?.toLowerCase() === "copy_code" ? "coupon_code" : "text";
                                let numberStr = "st"
                                if (index === 1) numberStr = "nd"
                                else if (index === 2) numberStr = "rd"
                                else if (index > 2) numberStr = "th"
                                if (staticButtonTypes.includes(button?.type) || (button?.type === "URL" && button?.urlType === "STATIC")) {
                                    return (
                                        <TextField
                                            fullWidth
                                            style={{ marginBottom: "24px" }}
                                            disabled
                                            label={` ${getLabelFromButtonType(button?.type?.toLowerCase())} - ${index +1}${numberStr} Button`}
                                            value={button?.["text"]}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <div key={`button-${index}`} style={{ display: "flex", gap: "12px", marginBottom: "24px" }}>
                                            <CustomInput
                                                helperText={getHelperText(button)}
                                                label={` ${getLabelFromButtonType(button?.type?.toLowerCase())} - ${index +1}${numberStr} Button`}
                                                value={buttonParamsObj?.find(button => button?.index === index)?.parameters?.[0]?.[buttonType]}
                                                variables={variables}
                                                handleInputChange={(value) => handleVariableSelection(value, index, button)}
                                                handleDropdownChange={(value) => handleVariableSelection(value, index, button, true)}
                                                showPopover={showPopover && selectedIndex === index}
                                                setShowPopover={setShowPopover}
                                                handleAddVariableButton={() => setSelectedIndex(index)}
                                            />
                                            {button?.type?.toLowerCase() === "copy_code" ? <TextField onChange={(e) => handleVariableSelection(e.target.value, index, button, false, true)} label="Fallback" /> : null}
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <br />
                    <Button
                        id="oru-complex-button"
                        data-testid="oru-complex-button"
                        className="oru-carousel-button"
                        onClick={() => {
                            const result = validateFinalData();
                            if (result) {
                                handleSave();
                            }
                        }}>Save</Button>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default ComplexTemplates;
