import axios from 'axios';
import { formatDate } from '../helper/utils';
const oracleEndpoint = process.env.REACT_APP_ORACLE_ENDPOINT;
const wrapperEndpoint = process.env.REACT_APP_GUPSHUP_WRAPPER_API_URL

const getChannels =  async (installId) => {
    const res = await axios({
        method: 'post',
        url: `${oracleEndpoint}/getChannels`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {installId: installId}
    })
    return res;
}

const getTemplates =  async (data) => {
    const res = await axios({
        method: 'post',
        url: `${oracleEndpoint}/getTemplates`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {userId: data.userId, password: data.password}
    })
    return res;
}

const getSummary = async (installId, data) => {
    const res = await axios(`${oracleEndpoint}/summery?orgId=${installId}&channelId=${data?.channel}&startDate=${formatDate(data?.dateRange?.startDate)}&endDate=${formatDate(data?.dateRange?.endDate)}`);
    return res?.data?.data;
}

const getMetadata = async (installId, data) => {
    const res = await axios(`${oracleEndpoint}/campaignMeta?orgId=${installId}&channelId=${data?.channel}&startDate=${formatDate(data?.dateRange?.startDate)}&endDate=${formatDate(data?.dateRange?.endDate)}`);
    const templates = [];
    const programs = [];
    res?.data?.data?.programs?.map((program) => {
        programs.push({label: program.id, value: program.id})
    })
    res?.data?.data?.templates?.map((template) => {
        templates.push({label: template.id, value: template.id})
    })
    return {templates, programs};
}

const getTableData = async (installId, data) => {
    const res = await axios(`${oracleEndpoint}/report?orgId=${installId}&channelId=${data?.channel}&startDate=${formatDate(data?.dateRange?.startDate)}&endDate=${formatDate(data?.dateRange?.endDate)}&limit=100&offset=0`);
    return res?.data;
}

const getFilteredTableData = async (installId, data) => {
    const programs = data?.programs;
    const templates = data?.templates;
    let filter = {};
    if(programs?.length > 0) filter["programIds"] = programs;
    if(templates?.length > 0) filter["templateIds"] = templates;
    let filterAPIURL = `${oracleEndpoint}/report?orgId=${installId}&channelId=${data?.channel}&startDate=${formatDate(data?.dateRange?.startDate)}&endDate=${formatDate(data?.dateRange?.endDate)}&limit=100&offset=${data?.page-1}`
    if(Object.keys(filter).length > 0) filterAPIURL += `&filter=${JSON.stringify(filter)}`
    const res = await axios(filterAPIURL);
    return res?.data;
}

const getExportData = async (installId, data) => {
    const programs = data?.programs;
    const templates = data?.templates;
    let filter = {};
    if(programs?.length > 0) filter["programIds"] = programs;
    if(templates?.length > 0) filter["templateIds"] = templates;
    let filterAPIURL = `${oracleEndpoint}/report?orgId=${installId}&channelId=${data?.channel}&startDate=${formatDate(data?.dateRange?.startDate)}&endDate=${formatDate(data?.dateRange?.endDate)}&limit=${data?.offset}&offset=0`
    if(Object.keys(filter).length > 0) filterAPIURL += `&filter=${JSON.stringify(filter)}`
    const res = await axios(filterAPIURL);
    return res?.data?.data;
}

const validateEnterpriseChannel = async (userId, password) => {
    const res = await axios(
        `${wrapperEndpoint}gupshup/enterprise/authenticate?userid=${userId}&password=${encodeURIComponent(
            password
        )}`
    );
    return res;
};

const uploadMedia = async (data) => {
    const res = await axios({
        method: 'post',
        url: `${wrapperEndpoint}gupshup/uploadMedia`,
        data: data
    })
    return res;
}

const getTemplateDetails = async (id) => {
    const res = await axios(`${oracleEndpoint}/getSelectedTemplate?instanceId=${id}`)
    return res?.data?.payload;
}

const deleteChannel =  async (installId, channelId) => {
    const res = await axios({
        method: 'post',
        url: `${oracleEndpoint}/deleteChannel`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {installId, channelId}
    })
    return res;
}

const updateChannel =  async (installId, payload) => {
    const res = await axios({
        method: 'post',
        url: `${oracleEndpoint}/updateChannel`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {installId, ...payload}
    })
    return res;
}

export {
    getChannels,
    getSummary,
    getMetadata,
    getTableData,
    getFilteredTableData,
    getExportData,
    getTemplates,
    validateEnterpriseChannel,
    uploadMedia,
    getTemplateDetails,
    deleteChannel,
    updateChannel,
}
