import { sendMessageToParent, trimMultipleSpacesToSingle, updateChannelState } from "../helper/utils";
import { updateChannel, validateEnterpriseChannel } from "./api";
// Function to check if a string is empty or contains only whitespace
const isEmptyOrWhitespace = (inputString) => {
  return !inputString || inputString.trim() === "";
}


export const DLRColumns = [
  {
    dataType: 'string',
    id: 'programId',
    label: 'Program ID',
  },
  {
    dataType: 'string',
    id: 'templateId',
    label: 'Template ID',
  },
  // {
  //   dataType: 'string',
  //   id: 'templateName',
  //   label: 'Template Name',
  // },
  {
    dataType: 'string',
    id: 'triggered',
    label: 'Triggered',
  },
  // {
  //   dataType: 'string',
  //   id: 'failedAtEnterprise',
  //   label: 'Failed At Enterprise',
  // },
  {
    dataType: 'string',
    id: 'sent',
    label: 'Sent',
  },
  {
    dataType: 'string',
    id: 'delivered',
    label: 'Delivered',
  },
  {
    dataType: 'string',
    id: 'read',
    label: 'Read',
  },
  {
    dataType: 'string',
    id: 'failed',
    label: 'Failed',
  },
  {
    dataType: 'string',
    id: 'others',
    label: 'Others',
    width: '50%'
  },
  {
    dataType: 'string',
    id: 'waExperimentFail',
    label: 'WA Experiment Fail',
    sx: {
      textWrap: "wrap"
    }
  },
  {
    dataType: 'string',
    id: 'unknownSubscriber',
    label: 'Invalid No.',
  },
  {
    dataType: 'date',
    id: 'launchTimestamp',
    label: 'Launch Time',
    sx: {
      whiteSpace: "normal"
    },
    Cell: (params) => new Date(params.row.launchTimestamp).toLocaleString()
  },
  {
    dataType: 'string',
    id: 'lastTimestamp',
    label: 'Last Time',
    sx: {
      whiteSpace: "normal"
    },
    Cell: (params) => new Date(params.row.lastTimestamp).toLocaleString()
  },
]

export const types = (orgId, channels, setShowLoading, setChannelDetails, setMessage, setShow, setShowError) => [
  {
    label: "Accounts Details",
    value: "enterprise",
    fields: [
      {
        key: "channelName",
        label: "Channel Name",
        type: "textField",
        helperText: "Name of your channel.",
        isRequired: true,
        isEditable: true
      },
      {
        key: "userId",
        label: "HSM Account ID",
        type: "textField",
        helperText: "The HSM account id of your Gupshup account.",
        isRequired: true
      },
      {
        key: "password",
        label: "Password",
        type: "textField",
        helperText: "The password of your Gupshup account for authentication of the userid(HSM Account password).",
        isRequired: true,
        fieldType: "password",
        isEditable: true
      }
    ],
    callback: async (data) => {
      if (!data) return 1

      const { channelName, userId, password } = data;

      if (isEmptyOrWhitespace(channelName) || isEmptyOrWhitespace(userId) || isEmptyOrWhitespace(password)) {
        return 1; // Data is incomplete
      }

      const existingChannel = channels?.find(channel =>
        channel.label?.toLowerCase() === channelName.toLowerCase() ||
        channel.userId?.toLowerCase() === userId.toLowerCase()
      );

      if (existingChannel) {
        return 2; // Channel already exists
      }

      try {
        await validateEnterpriseChannel(userId, password);
      } catch (error) {
        return 3; // Validation failed
      }
      // setShowLoading(true)
      data.channelName = trimMultipleSpacesToSingle(channelName)
      sendMessageToParent({ type: 'accountData', payload: data });
      return 0; // Success
    },
    updateCallback: async (data) => {
      const payload = {
        password: data?.formData?.password,
        channelName: data?.formData?.channelName,
        channelId: data?.selectedChannel?.channelId,
        userId: data?.selectedChannel?.userId,
      }
      const res = await updateChannel(orgId, payload)
      if(res?.data?.message == 'Gupshup account updated successfully') {
        await updateChannelState(orgId, "updated", setChannelDetails, setMessage, setShow)
      } else {
        setShowError(true)
      }
    }
  }
];