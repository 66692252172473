import { TemplateMessaging } from "@gs/integration-components";
import { useEffect, useState } from "react";
import { getChannels, getTemplateDetails, getTemplates } from "../../utils/api";
import { CircularProgress, DatePicker, Switch, ThemeProvider } from "@gupshup-frontend/gupshup-ts-components";
import { extractVariables, sendMessageToParent } from "../../helper/utils";
import { POSSIBLE_PHONE_VARIABLES } from "../../helper/constants";
import { uploadMedia } from "../../utils/api";
const TemplateScreen = () => {
    const [channels, setChannels] = useState([]);
    const [variables, setVariables] = useState([]);
    const [phoneVariables, setPhoneVariables] = useState([]);
    const [scheduleType, setScheduleType] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [scheduleTime, setScheduleTime] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [defaultValue, setDefaultValue] = useState();
    const [isLoading, setIsloading] = useState(false);
    useEffect(() => {
        sendMessageToParent("ready");
        window.onmessage = (event) => {
            setIsloading(true);
            if (event?.data?.name === "props" && event?.data?.variables || localStorage.getItem('variables')) {
                if (event?.data?.variables) localStorage.setItem('variables', JSON.stringify(event?.data?.variables))
                let variables = event?.data?.variables || JSON.parse(localStorage.getItem('variables'));
                (async () => {
                    const tempPhoneList = [];
                    const tempVariables = variables.map((v, i) => {
                        if (POSSIBLE_PHONE_VARIABLES.some((ph) => v?.name?.toLowerCase()?.includes(ph))) {
                            tempPhoneList.push({ label: v.name, value: `${i}` })
                        }
                        return {
                            label: v.name,
                            value: `{${v.name}}`,
                        }
                    })
                    setPhoneVariables(tempPhoneList)
                    setVariables(tempVariables)
                    const response = await getChannels(event.data?.installId);
                    const tempChannels = [];
                    response?.data?.channels?.map((channel, i) => {
                        tempChannels.push({ ...channel, label: channel?.channelName, value: `${i + 1}`, type: "enterprise" })
                    })
                    setChannels(tempChannels)
                })();
                (async () => {
                    const tempDetails = await getTemplateDetails(event.data?.instanceId)
                    if (tempDetails?.selectedTemplate) {
                        let params = tempDetails?.parameterList.map((param) => `{${param}}`)
                        if (tempDetails?.scheduledTimestamp) {
                            setScheduleType(true);
                            setScheduleTime(new Date(tempDetails.scheduledTimestamp));
                        }
                        if (tempDetails?.waTemplateJson) params.push(tempDetails?.waTemplateJson);
                        if (tempDetails) {
                            const defaultData = {
                                variables: params,
                                media: tempDetails?.mediaUrl,
                                dynamicButton: tempDetails?.trackingUrl,
                                template: {
                                    ...tempDetails?.selectedTemplate,
                                    details: {
                                        ...tempDetails?.selectedTemplate?.details,
                                        containerMeta: JSON.stringify(tempDetails?.selectedTemplate?.details?.containerMeta)
                                    }
                                },
                            }
                            setDefaultValue({
                                selectedChannel: tempDetails?.channelId,
                                selectedPhoneNumber: tempDetails?.sendTo,
                            })
                            setSelectedTemplate(defaultData)
                        }
                    }
                })();
            }
            setIsloading(false);
        }
    }, [])
    const callbackMethod = async (action, data) => {
        if (action === 'channelChange') {
            const selectedChannel = channels?.find((o) => o.label == data)
            const response = await getTemplates({ userId: selectedChannel?.userId, password: selectedChannel?.password })
            if (response?.data?.data) {
                const approvedTemplates = response.data.data.filter((temp) => temp.status === "ENABLED")
                // setTemplateList(approvedTemplates)
                const templatesList = approvedTemplates.map((temp, index) => {
                    const cards = [];
                    if (temp?.carouselCards) {
                        temp.carouselCards.map((card) => {
                            const comp = {}
                            card.components.map((component) => {
                                switch (component?.type) {
                                    case "header":
                                        comp["headerType"] = component?.format;
                                        break;
                                    case "body":
                                        comp["body"] = component?.text || "";
                                        break;
                                    case "buttons":
                                        comp["buttons"] = component?.buttons;
                                        break;
                                }
                            })
                            cards.push(comp)
                        })
                    }
                    return {
                        label: temp.name,
                        value: index + 1,
                        details: { ...temp, containerMeta: { ...temp, data: temp?.body, cards }, templateType: temp?.button_type === "CAROUSEL" ? "CAROUSEL" : temp?.type, data: temp?.body },
                    }
                })
                setTemplateList(templatesList)
                return templatesList;
            }
            return [];
        } else if (action === 'submit') {
            const selectedChannelId = channels.find((channel) => channel?.label === data?.channelName)?.channelId
            const phoneVar = phoneVariables.find((variable) => variable.value === data.destination)?.label
            let params = [];
            let waTemplateParams = [];
            if(data?.headerParams) {
                params = [...data.headerParams.map(item => item.replace(/\{(.+?)\}/, '$1'))]
            }
            if (data?.wa_template_json) {
                waTemplateParams = extractVariables(data.wa_template_json);
            }
            const selectedTemplate = templateList?.find((template) => template?.details?.id == data?.templateId)
            let finalPayload = {
                channelId: selectedChannelId,
                parameterList: [...params,
                ...data.params.map(item => item.replace(/\{(.+?)\}/, '$1')),
                ...waTemplateParams,
                    phoneVar],
                templateId: parseInt(data?.templateId),
                msgId: "",
                msgType: selectedTemplate?.details?.type,
                waTemplateJson: data?.wa_template_json,
                trackingUrl: data?.buttonUrlParam || data?.ctaButtonUrl || null,
                scheduledTimestamp: scheduleType ? scheduleTime : null,
                selectedTemplate: selectedTemplate,
                sendTo: phoneVar,
            }
            if (data.mediaUrl) finalPayload["mediaUrl"] = data.mediaUrl
            sendMessageToParent({ type: "payload", payload: finalPayload })
            return true;
        } else if (action === 'upload') {
            let body = new FormData();
            body.append('file', data);
            let res = await uploadMedia(body);
            return res?.data?.data;
        }
    }
    return (
        <ThemeProvider>
            <div style={{ padding: "12px" }}>
                {channels?.length > 0 && <div>
                    <Switch
                        color="primary"
                        data-testid="switch-testid"
                        label="Do you want to schedule campaign"
                        labelPlacement="start"
                        checked={scheduleType}
                        onChange={(event) => setScheduleType(event.target.checked)}
                    />
                    <span style={{ marginLeft: "30px" }}>{scheduleType &&
                        <DatePicker
                            inputFormat="dd/MM/yyyy hh:mm a"
                            label="Schedule Date and Time"
                            size="small"
                            onChange={(value) => setScheduleTime(value)}
                            disablePast={true}
                            value={scheduleTime || new Date()}
                            views={[
                                "year",
                                "month",
                                "day",
                                "hours",
                                "minutes"
                            ]}
                        />
                    }  </span>
                </div>}
                {isLoading ? <div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}><CircularProgress /></div>
                    : channels?.length > 0 &&
                    phoneVariables?.length > 0 &&
                    variables?.length > 0 &&
                    <TemplateMessaging
                        key={selectedTemplate}
                        channels={channels}
                        phoneNumbers={phoneVariables}
                        config={{
                            appName: "oracle",
                            orgId: ""
                        }}
                        callbackMethod={callbackMethod}
                        variables={variables}
                        defaultChannel={channels.find((channel) => channel.channelId === defaultValue?.selectedChannel)}
                        defaultSender={phoneVariables.find((phone) => phone.label == defaultValue?.selectedPhoneNumber)?.value}
                        defaultTemplate={selectedTemplate || null}
                        customisations={{
                            sendButtonText: "Save",
                            onlyVariable: true,
                            hideSuccessMessage: true,
                        }}
                />}
                {
                    channels?.length === 0 && <div>No channel found! Please add an channel in App Management.</div>
                }
            </div>
        </ThemeProvider>
    )
}
export default TemplateScreen;
