import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OracleCarousel from "./pages/OracleCarousel";
import Dashboard from './pages/Dashboard';
import AppConfig from './pages/AppConfig';
import ComplexTemplates from './pages/ComplexTemplates';
import TemplateScreen from './pages/TemplateScreen';
import "./index.css"
import Configuration from './pages/Configuration';
function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<OracleCarousel />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/template-screen" element={<TemplateScreen />} />
          <Route exact path="/appconfig" element={<AppConfig />} />
          <Route exact path="/complex-templates" element={<ComplexTemplates />} />
          <Route exact path="/config" element={<Configuration />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
