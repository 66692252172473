import { deleteChannel, getChannels } from "../utils/api";

const variableCount = (str) => {
    const re = /{{(([^}][^}]?|[^}]}?)*)}}/g;
    return ((str || "").match(re) || []).length;
};

function isImage(url) {
    return /^https?:\/\/.+\.(jpg|jpeg|png)$/.test(url);
}
function isVideoUrl(url) {
    const fileExtension = url.split('.').pop().toLowerCase();
    const videoExtensions = ['mp4'];
    return videoExtensions.includes(fileExtension);
}

const sendMessageToParent = (message) => {
    if (window?.parent) {
        window.parent.postMessage(message, '*');
      }
}

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const csvmaker = function (data) {
    // Empty array for storing the values 
    const csvRows = [];

    // Headers is basically a keys of an 
    // object which is id, name, and 
    // profession 
    const headers = Object.keys(data[0]);

    // As for making csv format, headers  
    // must be separated by comma and 
    // pushing it into array 
    csvRows.push(headers.join(','));

    // Pushing Object values into array 
    // with comma separation for each object in the array
    data.forEach(obj => {
        const values = headers.map(header => obj[header]).join(',');
        csvRows.push(values);
    });

    // Returning the array joining with new line  
    return csvRows.join('\n');
}

function extractVariableValues(str) {
    const regex = /{{(.*?)}}/g;
    const matches = [];
    let match;
    
    while ((match = regex.exec(str)) !== null) {
        matches.push(match[1].trim());
    }
    
    return matches;
}

function isDocument(url) {
    return /^https?:\/\/.+\.(pdf)$/.test(url);
}

const isMediaUrlValid = (type, url) => {
    if(type === "IMAGE")
      return isImage(url)
    else if(type === "VIDEO")
      return isVideoUrl(url)
    else if(type === "DOCUMENT")
      return isDocument(url)
    else return false
}

const getLabelFromButtonType = (type) => {
    switch(type) {
        case "copy_code": return "Copy Code";
        case "quick_reply": return "Quick Reply";
        case "url": return "URL";
        case "phone_number": return "Phone Number";
        default: return type;
    }
}

const  trimMultipleSpacesToSingle = (inputString) => {
    return inputString.replace(/\s+/g, ' ').trim();
}

const extractVariables = (obj) => {
    let values = [];

    // Check if the input is an object
    if (typeof obj === 'object' && obj !== null) {
        // If it's an array, iterate over its elements
        if (Array.isArray(obj)) {
            obj.forEach(item => {
                // Recursively extract values from each array element
                values = values.concat(extractVariables(item));
            });
        } else {
            // If it's an object, iterate over its properties
            Object.values(obj).forEach(value => {
                // Recursively extract values from each property
                values = values.concat(extractVariables(value));
            });
        }
    } else if (typeof obj === 'string') {
        // If the input is a string, check if it contains curly braces {}
        const matches = obj.match(/\{(.*?)\}/g);
        if (matches) {
            // If curly braces are found, extract the values within them
            matches.forEach(match => {
                // Remove curly braces and push the value into the result array
                const value = match.substring(1, match.length - 1);
                values.push(value);
            });
        }
    }

    return values;
}

const updateChannelState = async (orgId, message, setChannelDetails, setMessage, setShow) => {
    setMessage({ title: `Successfully ${message} channel!`, message: `Successfully ${message} channel!` })
    setShow(true);
    setTimeout(async () => {
        const userData = await getChannels(orgId);
        setChannelDetails(userData.data);
    }, 1000)
}

const handleDeleteChannel = async (selectedChannel, orgId, setChannelDetails, setShow, setShowError, setMessage, setChannelId) => {
    const res = await deleteChannel(orgId, selectedChannel?.channelId);
    if (res?.status == 200) {
        setChannelId(selectedChannel?.channelId)
        await updateChannelState(orgId, "deleted the", setChannelDetails, setMessage, setShow)
    } else {
        setShowError(true);
    }
}

export {
    variableCount,
    isImage,
    isVideoUrl,
    sendMessageToParent,
    formatDate,
    csvmaker,
    extractVariableValues,
    isMediaUrlValid,
    getLabelFromButtonType,
    trimMultipleSpacesToSingle,
    extractVariables,
    handleDeleteChannel,
    updateChannelState,
}