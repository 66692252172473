import React, { useEffect, useState } from "react";
import { ChannelConfig } from "@gs/integration-components";
import { getChannels } from "../../utils/api";
import { handleDeleteChannel, sendMessageToParent } from "../../helper/utils";
import { CircularProgress, Toast } from "@gupshup-frontend/gupshup-ts-components";
import { types } from "../../utils/constants";
import "./index.css";

const AppConfig = () => {
  const [channelDetails, setChannelDetails] = useState({});
  const [channelId, setChannelId] = useState('');
  const [orgId, setOrgId] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState({ title: "", description: "" });
  useEffect(() => {
    sendMessageToParent("accountsReady");

    const handleMessage = async (event) => {
      if (event.data.name === "getAllChannels" && event.data.installId) {
        try {
          setOrgId(event.data.installId);
          setShowLoading(true);
          const res = await getChannels(event.data.installId);
          setChannelDetails(res.data);
          setShowLoading(false);
        } catch (err) {
          console.log("error-------------", err);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="AppConfig">
      {!showLoading && (
        <ChannelConfig
          key={channelId}
          channels={channelDetails?.channels?.map((channel, index) => ({
            label: channel.channelName,
            value: `${index + 1}`,
            type: 'enterprise',
            tab: 0,
            ...channel
          }))}
          handleCancel={() => {
            window.location.reload();
          }}
          meta={
            {deletePrompt: "Please make sure that this channel is not associated to any upcoming campaigns"}
          }  
          channelTypes={types(orgId, channelDetails?.channels, setShowLoading, setChannelDetails, setMessage, setShow, setShowError)}
          handleDelete={(selectedChannel) => handleDeleteChannel(selectedChannel, orgId, setChannelDetails, setShow, setShowError, setMessage, setChannelId)}
        />
      )}
      {showLoading && (
        <div className='form-bulk-loader'>
          <CircularProgress thickness={4.5} data-testid="circular-progress-testid" />
        </div>
      )}
      <Toast
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
        data-testid="toast-testid"
        maxWidth="30vw"
        onClose={() => setShow(false)}
        title={message.title}
        type="success"
        open={show}
        autoHideDuration={3000}
      />
      <Toast
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
        data-testid="toast-testid"
        maxWidth="30vw"
        onClose={() => setShowError(false)}
        title={"Something went Wrong"}
        description={"Something went Wrong"}
        type="error"
        open={showError}
        autoHideDuration={3000}
      />
    </div>
  );
};

export default AppConfig;
