import { Tabs, ThemeProvider } from "@gupshup-frontend/gupshup-ts-components";
import Dashboard from '../Dashboard';
import AppConfig from "../AppConfig";
import { useState } from "react";

const Configuration = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    return (
        <>
            <ThemeProvider>
                <Tabs
                    data={[
                        {
                            content: <AppConfig />,
                            label: "Account Settings"
                        },
                        {
                            content: <div style={{paddingRight: "48px"}}><Dashboard /></div>,
                            label: "Dashboard",
                        }
                    ]}
                    value={selectedTab}
                    handleTabChange={(e, value) => setSelectedTab(value)}
                />
            </ThemeProvider>
        </>
    )
}
export default Configuration;
